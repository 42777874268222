import $ from "jquery";
import { baseUrl } from "./utils.js";

$(function () {
  $("#medical_institution").on("keyup", function (obj) {
    if (obj.originalEvent.isComposing) return;
    let cond = $("#medical_institution").val();
    searchMedicalInstitutions(cond);
  });

  $("#medical_institution").on("keydown", function (obj) {
    let e = obj.originalEvent;
    if (e.isComposing) return;
    if (e.code && e.code === "Enter") {
      return false;
    } else {
      return true;
    }
  });

  function searchMedicalInstitutions(inputText) {
    $.ajax({
      url: baseUrl() + "/medical_care_histories/search_medical_institutions",
      type: "GET",
      data: { name_cond: inputText },
      success: function (data) {
        $("#medical_institutions").empty();
        if (data.length != 0) {
          $.each(data, function (index, value) {
            let $item = $("<div></div>", { addClass: "combobox_item" });
            $item.append(
              $(
                `<a href="javascript:void(0)" class="medical_institution_link" data-id='${value.id}' data-name='${value.name}'>${value.name}</a>`
              )
            );
            $("#medical_institutions").append($item);
          });

          $(".combobox").show();
          $(".medical_institution_link").on("click", function (obj) {
            $("#medical_institution_name").text(obj.currentTarget.dataset.name);
            $("#medical_care_history_inpatient_medical_institution_id").val(
              obj.currentTarget.dataset.id
            );
            // その他の場合入力欄を出す
            if (obj.currentTarget.dataset.name == "その他") {
              $("#inpatient_medical_institution_other_name").show();
            } else {
              $("#inpatient_medical_institution_other_name").hide();
              $(
                "#medical_care_history_inpatient_medical_institution_other_name"
              ).val("");
            }
            // 検索内容をクリアする
            $("#medical_institution").val("");
            searchMedicalInstitutions("");
          });
        } else if (inputText.length > 0) {
          let $item = $("<div></div>", { addClass: "combobox_item" });
          $item.append("該当なし");
          $("#medical_institutions").append($item);
          $(".combobox").show();
        } else {
          $(".combobox").hide();
        }
      },
    });
  }
});
