import $ from "jquery";
import { baseUrl } from "./utils.js";

$(function () {
  $("#inspection_agency").on("keyup", function (obj) {
    if (obj.originalEvent.isComposing) return;
    let cond = $("#inspection_agency").val();
    searchInspections(cond);
  });

  $("#inspection_agency").on("keydown", function (obj) {
    let e = obj.originalEvent;
    if (e.isComposing) return;
    if (e.code && e.code === "Enter") {
      return false;
    } else {
      return true;
    }
  });

  function searchInspections(inputText) {
    $.ajax({
      url: baseUrl() + "/specimens/search_inspection_agencies",
      type: "GET",
      data: { name_cond: inputText },
      success: function (data) {
        $("#inspection_agencies").empty();
        if (data.length != 0) {
          $.each(data, function (index, value) {
            let $item = $("<div></div>", { addClass: "combobox_item" });
            $item.append(
              $(
                `<a href="javascript:void(0)" class="inspection_agencies_link" data-id='${value.id}' data-name='${value.name}'>${value.name}</a>`
              )
            );
            $("#inspection_agencies").append($item);
          });

          $(".combobox").show();
          $(".inspection_agencies_link").on("click", function (obj) {
            $("#inspection_agency_name").text(obj.currentTarget.dataset.name);
            $("#specimen_inspection_agency_id").val(
              obj.currentTarget.dataset.id
            );
            // その他の場合入力欄を出す
            if (obj.currentTarget.dataset.name == "その他") {
              $("#inspection_agency_other_name").show();
            } else {
              $("#inspection_agency_other_name").hide();
              $("#specimen_inspection_agency_other_name").val("");
            }
            // 検索内容をクリアする
            $("#inspection_agency").val("");
            searchInspections("");
          });
        } else if (inputText.length > 0) {
          let $item = $("<div></div>", { addClass: "combobox_item" });
          $item.append("該当なし");
          $("#inspection_agencies").append($item);
          $(".combobox").show();
        } else {
          $(".combobox").hide();
        }
      },
    });
  }

  // virus_type が other だったら、virus_name_other の入力欄を表示する。それ以外は非表示。
  if ($('#specimen_virus_type_group input[type="radio"]:checked').val() !== 'other') {
    $('#form_group_row_specimen_virus_type_other').hide();
  }
  $('#specimen_virus_type_group input[type="radio"]').on("change", function () {
    if ($(this).val() === 'other') {
      $('#form_group_row_specimen_virus_type_other').show();
    } else {
      $('#form_group_row_specimen_virus_type_other').hide();
    }
  });
});
